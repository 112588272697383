import React from "react";

const About: React.FC = () => {
  return (
    <div className="w-full bg-gray-50 py-16">
      <div className="max-w-7xl mx-auto px-4">
        {/* Company History */}
        <div className="text-center mb-20">
          <h1 className="text-4xl font-bold text-gray-800 mb-4">
            Company History
          </h1>
          <div className="h-1 w-32 bg-[#F85B20] mx-auto mb-8"></div>
          <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-sm">
            <p className="text-gray-700 leading-relaxed">
              Since our establishment in 2012, CashNet Finance has grown to
              become one of Namibia's most trusted financial institutions. We
              specialize in providing accessible short-term and long-term loans
              to Government employees, Private sector workers, and Pensioners,
              fully compliant with NAMFISA regulations. Our streamlined
              processes and customer-first approach have helped thousands of
              Namibians achieve their financial goals through transparent,
              ethical lending practices.
            </p>
          </div>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {/* Vision Card */}
          <div className="bg-[#F85B20] p-8 rounded-xl text-white">
            <h2 className="text-2xl font-bold mb-6">Vision</h2>
            <div className="space-y-6">
              <p>
                To be Namibia's most trusted financial partner, providing
                accessible and ethical lending solutions that empower
                individuals to achieve their financial goals.
              </p>
              <p>
                We strive for excellence in every interaction, ensuring swift
                service delivery while maintaining the highest standards of
                transparency and professionalism.
              </p>
            </div>
          </div>

          {/* Mission Card */}
          <div className="bg-[#F85B20] p-8 rounded-xl text-white">
            <h2 className="text-2xl font-bold mb-6">Mission</h2>
            <div className="space-y-6">
              <p>
                To deliver reliable and accessible financial solutions through
                transparent, flexible, and customer-focused services that make a
                real difference in people's lives.
              </p>
              <p>
                We are committed to fostering financial stability and growth
                while maintaining the highest standards of ethical lending
                practices.
              </p>
            </div>
          </div>

          {/* Values Card */}
          <div className="bg-[#F85B20] p-8 rounded-xl text-white">
            <h2 className="text-2xl font-bold mb-6">Values</h2>
            <div className="space-y-4">
              <p>
                <span className="font-semibold">Excellence:</span> Delivering
                outstanding service in every interaction
              </p>
              <p>
                <span className="font-semibold">Integrity:</span> Operating with
                honesty and ethical principles
              </p>
              <p>
                <span className="font-semibold">Transparency:</span> Maintaining
                clear and open communication
              </p>
              <p>
                <span className="font-semibold">Respect:</span> Treating every
                client with dignity and understanding
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
