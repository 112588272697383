import React from "react";
import LoanCalculator from "../components/LoanCalculator";

const CalculatorPage: React.FC = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-800 mb-4">
          Loan Calculator
        </h1>
        <p className="text-gray-600 text-lg">
          Calculate your monthly repayments and see our flexible loan options
        </p>
      </div>

      <div className="flex justify-center">
        <div className="w-full max-w-xl">
          <LoanCalculator />
        </div>
      </div>
    </div>
  );
};

export default CalculatorPage;
