import React from "react";
import { MapPin, Phone, Clock, Mail } from "lucide-react";

const BranchInfo = {
  windhoek: {
    name: "Windhoek Branch",
    address: [
      "51 55 Werner List Street",
      "City Centre Building, ground floor",
      "next to Frans Indongo Garden",
      "ERF 2243",
    ],
    phones: ["+264 61 231 865", "+264 81 478 5712"],
    email: "cashnett50@gmail.com",
    hours: {
      weekday: "07:30 – 16:30",
      saturday: "08:00 – 12:30",
    },
    mapUrl: "https://maps.app.goo.gl/aawhVoLSgYbiGZbW8",
  },
  okahandja: {
    name: "Okahandja Branch",
    address: ["Shop No. 31", "Okahandja Shopping Centre", "Next to MTC"],
    phones: ["+264 81 790 9909", "+264 81 791 0885"],
    email: "cashnettokahandja@gmail.com",
    hours: {
      weekday: "07:30 – 16:30",
      saturday: "08:00 – 12:30",
    },
    mapUrl: "https://maps.app.goo.gl/jM8tp5SmVyVZvAdS9",
  },
};

const Branches: React.FC = () => {
  const handleMapOpen = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const handleWhatsApp = (phone: string) => {
    const whatsappNumber = phone.replace(/[^0-9]/g, "");
    window.location.href = `https://wa.me/${whatsappNumber}`;
  };

  const handleEmail = (email: string) => {
    window.location.href = `mailto:${email}`;
  };

  return (
    <div className="w-full bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 py-16">
        <div className="text-center text-white mb-12">
          <h2 className="text-4xl font-bold mb-4">Find Nearest Branch Today</h2>
          <p className="text-lg">
            Discover your nearest branch today for quick cash solutions
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
          {Object.values(BranchInfo).map((branch) => (
            <div
              key={branch.name}
              className="bg-white rounded-lg p-6 shadow-lg"
            >
              <h3 className="text-xl font-semibold text-gray-800 mb-4">
                {branch.name}
              </h3>
              <div className="space-y-3 mb-6">
                <div className="flex items-start gap-3 text-gray-600">
                  <MapPin size={20} className="flex-shrink-0 mt-1" />
                  <div className="flex flex-col">
                    {branch.address.map((line, index) => (
                      <span key={index}>{line}</span>
                    ))}
                  </div>
                </div>
                <div className="flex items-start gap-3 text-gray-600">
                  <Phone size={20} className="flex-shrink-0 mt-1" />
                  <div className="flex flex-col">
                    {branch.phones.map((phone, index) => (
                      <span key={index}>{phone}</span>
                    ))}
                  </div>
                </div>
                <div className="flex items-start gap-3 text-gray-600">
                  <Clock size={20} className="flex-shrink-0 mt-1" />
                  <div className="flex flex-col">
                    <span>Mon-Fri: {branch.hours.weekday}</span>
                    <span>Sat: {branch.hours.saturday}</span>
                  </div>
                </div>
              </div>

              <div className="space-y-3">
                {/* Map Button */}
                <button
                  onClick={() => handleMapOpen(branch.mapUrl)}
                  className="w-full bg-[#F85B20] text-white px-6 py-3 rounded-full hover:bg-[#E04E1A] transition-colors flex items-center justify-center gap-2"
                >
                  <MapPin size={20} />
                  View on Maps
                </button>

                {/* WhatsApp Button - Using the second number (mobile) if available */}
                <button
                  onClick={() =>
                    handleWhatsApp(branch.phones[1] || branch.phones[0])
                  }
                  className="w-full bg-green-600 text-white px-6 py-3 rounded-full hover:bg-green-700 transition-colors flex items-center justify-center gap-2"
                >
                  <Phone size={20} />
                  WhatsApp Us
                </button>

                {/* Email Button */}
                <button
                  onClick={() => handleEmail(branch.email)}
                  className="w-full bg-gray-800 text-white px-6 py-3 rounded-full hover:bg-gray-900 transition-colors flex items-center justify-center gap-2"
                >
                  <Mail size={20} />
                  Email Us
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Branches;
