import React, { useState } from "react";
import { Cloud } from "lucide-react";

interface FormData {
  firstName: string;
  surname: string;
  idNumber: string;
  phone: string;
  email: string;
  message: string;
  branch: string;
  documents: null | File[];
}

interface FormFieldProps {
  label: string;
  name: string;
  type?: string;
  placeholder: string;
  required?: boolean;
}

const LoanForm = () => {
  const [formData, setFormData] = useState<FormData>({
    firstName: "",
    surname: "",
    idNumber: "",
    phone: "",
    email: "",
    message: "",
    branch: "Windhoek",
    documents: null,
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
  };

  const FormField: React.FC<FormFieldProps> = ({
    label,
    name,
    type = "text",
    placeholder,
    required = false,
  }) => (
    <div className="mb-6">
      <label className="block text-gray-700 text-sm font-medium mb-2">
        {label} {required && <span className="text-red-500">*</span>}
      </label>
      <input
        type={type}
        name={name}
        placeholder={placeholder}
        onChange={handleInputChange}
        className="w-full p-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-transparent transition-all bg-white"
        required={required}
      />
    </div>
  );

  return (
    <div className="w-full max-w-4xl mx-auto bg-white p-4 sm:p-8 rounded-xl shadow-lg">
      <h2 className="text-2xl font-bold text-gray-800 mb-6">
        Apply for a Loan
      </h2>

      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Personal Information */}
        <div className="lg:grid lg:grid-cols-2 lg:gap-4">
          <FormField
            label="First Name"
            name="firstName"
            placeholder="Enter your first name"
            required
          />
          <FormField
            label="Surname"
            name="surname"
            placeholder="Enter your surname"
            required
          />
        </div>

        <FormField
          label="ID Number"
          name="idNumber"
          placeholder="Enter your ID number (11 digits)"
          required
        />

        <FormField
          label="Phone Number"
          name="phone"
          type="tel"
          placeholder="Enter your phone number"
          required
        />

        <FormField
          label="Email Address"
          name="email"
          type="email"
          placeholder="Enter your email address"
          required
        />

        {/* Document Upload */}
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-medium mb-2">
            Required Documents
          </label>
          <div className="border-2 border-dashed border-gray-200 rounded-lg p-6 text-center hover:border-orange-500 transition-colors">
            <Cloud className="mx-auto h-12 w-12 text-orange-500" />
            <p className="mt-2 text-gray-600">
              Upload the following documents:
            </p>
            <ul className="text-sm text-gray-500 mt-1 list-disc list-inside">
              <li>3 months latest Bank statement</li>
              <li>2 recent payslips</li>
              <li>Namibian ID</li>
            </ul>
            <button
              type="button"
              className="mt-4 px-4 py-2 bg-white border border-gray-300 rounded-lg text-sm text-gray-700 hover:bg-gray-50"
            >
              Choose Files
            </button>
          </div>
        </div>

        {/* Branch Selection */}
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-medium mb-2">
            Branch <span className="text-red-500">*</span>
          </label>
          <div className="space-y-2">
            {["Windhoek", "Okahandja"].map((branch) => (
              <label
                key={branch}
                className="flex items-center space-x-2 p-2 hover:bg-gray-50 rounded-lg cursor-pointer"
              >
                <input
                  type="radio"
                  name="branch"
                  value={branch}
                  checked={formData.branch === branch}
                  onChange={handleInputChange}
                  className="text-orange-500 focus:ring-orange-500"
                />
                <span className="text-gray-700">{branch}</span>
              </label>
            ))}
          </div>
        </div>

        {/* Message */}
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-medium mb-2">
            Message
          </label>
          <textarea
            name="message"
            placeholder="Enter the loan amount needed and any additional message"
            className="w-full p-3 border border-gray-200 rounded-lg focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-transparent transition-all h-32 resize-none bg-white"
            onChange={handleInputChange}
          />
        </div>

        {/* reCAPTCHA */}
        <div className="mb-6">
          <div
            className="g-recaptcha"
            data-sitekey="your-recaptcha-site-key"
          ></div>
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-orange-500 text-white py-3 px-6 rounded-lg hover:bg-orange-600 transition-colors focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
        >
          Submit Application
        </button>
      </form>
    </div>
  );
};

export default LoanForm;
