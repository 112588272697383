import React, { useState } from "react";
import { Mail, Phone, Clock, X } from "lucide-react";
import { Link } from "react-router-dom";

interface BranchInfo {
  name: string;
  phones: string[];
  email: string;
  address: string[];
  hours: {
    weekday: string;
    saturday: string;
  };
  mapLink?: string;
}

const Footer: React.FC = () => {
  const [showBranchSelect, setShowBranchSelect] = useState(false);
  const [selectMode, setSelectMode] = useState<"whatsapp" | "email" | null>(
    null
  );
  const currentYear = new Date().getFullYear();

  const branches: BranchInfo[] = [
    {
      name: "Windhoek Branch",
      phones: ["+264 61 231 865", "+264 81 478 5712"],
      email: "cashnett50@gmail.com",
      address: [
        "51 55 Werner List Street",
        "City Centre Building, ground floor",
        "next to Frans Indongo Garden",
        "ERF 2243",
      ],
      hours: {
        weekday: "07:30 – 16:30",
        saturday: "08:00 – 12:30",
      },
      mapLink: "#",
    },
    {
      name: "Okahandja Branch",
      phones: ["+264 81 790 9909", "+264 81 791 0885"],
      email: "cashnettokahandja@gmail.com",
      address: ["Shop No. 31", "Okahandja Shopping Centre", "Next to MTC"],
      hours: {
        weekday: "07:30 – 16:30",
        saturday: "08:00 – 12:30",
      },
    },
  ];

  const handleBranchSelect = (branch: BranchInfo) => {
    if (selectMode === "whatsapp") {
      const whatsappNumber = branch.phones[1] || branch.phones[0];
      window.location.href = `https://wa.me/${whatsappNumber.replace(
        /[^0-9]/g,
        ""
      )}`;
    } else if (selectMode === "email") {
      window.location.href = `mailto:${branch.email}`;
    }
    setShowBranchSelect(false);
    setSelectMode(null);
  };

  const openBranchSelect = (mode: "whatsapp" | "email") => {
    setSelectMode(mode);
    setShowBranchSelect(true);
  };

  return (
    <footer className="bg-gray-900 text-white">
      <div className="max-w-7xl mx-auto px-4 py-12">
        {/* Contact Buttons */}
        <div className="flex flex-col sm:flex-row justify-center gap-4 mb-12">
          <Link
            to="/apply"
            className="flex items-center justify-center gap-2 bg-[#F85B20] hover:bg-[#E04E1A] px-8 py-3 rounded-full transition-colors text-white order-first sm:order-none"
          >
            Apply Now
          </Link>
          <button
            onClick={() => openBranchSelect("whatsapp")}
            className="flex items-center justify-center gap-2 bg-green-600 hover:bg-green-700 px-8 py-3 rounded-full transition-colors text-white"
          >
            <Phone className="w-5 h-5" />
            WhatsApp Us
          </button>
          <button
            onClick={() => openBranchSelect("email")}
            className="flex items-center justify-center gap-2 bg-[#F85B20] hover:bg-[#E04E1A] px-8 py-3 rounded-full transition-colors text-white"
          >
            <Mail className="w-5 h-5" />
            Email Us
          </button>
        </div>

        {/* Custom Modal */}
        {showBranchSelect && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
            <div className="bg-white rounded-lg p-6 max-w-lg w-full">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-bold text-[#F85B20]">
                  Select a Branch to{" "}
                  {selectMode === "whatsapp" ? "WhatsApp" : "Email"}
                </h2>
                <button
                  onClick={() => setShowBranchSelect(false)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <X className="w-6 h-6" />
                </button>
              </div>
              <div className="grid gap-6">
                {branches.map((branch) => (
                  <div
                    key={branch.name}
                    onClick={() => handleBranchSelect(branch)}
                    className={`
                      p-6 rounded-lg cursor-pointer transform transition-all duration-200
                      border-2 hover:border-[#F85B20] hover:shadow-lg hover:-translate-y-1
                      ${
                        selectMode === "whatsapp"
                          ? "bg-green-50"
                          : "bg-orange-50"
                      }
                      hover:bg-white
                    `}
                  >
                    <div className="flex items-center justify-between mb-4">
                      <h3 className="font-bold text-lg text-gray-800">
                        {branch.name}
                      </h3>
                      <span className="text-[#F85B20] font-medium">
                        Click to{" "}
                        {selectMode === "whatsapp" ? "message" : "email"} →
                      </span>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div>
                        <p className="font-semibold flex items-center gap-2 text-gray-700">
                          <Phone className="w-4 h-4" />
                          Contact
                        </p>
                        {branch.phones.map((phone) => (
                          <p key={phone} className="text-gray-600 ml-6">
                            {phone}
                          </p>
                        ))}
                      </div>
                      <div>
                        <p className="font-semibold flex items-center gap-2 text-gray-700">
                          <Clock className="w-4 h-4" />
                          Hours
                        </p>
                        <div className="ml-6">
                          <p className="text-gray-600">
                            Mon-Fri: {branch.hours.weekday}
                          </p>
                          <p className="text-gray-600">
                            Sat: {branch.hours.saturday}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {/* Links and Info */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
          {/* Quick Links */}
          <div className="text-center">
            <h3 className="font-bold text-lg mb-4 text-white">Quick Links</h3>
            <ul className="space-y-2">
              <li>
                <Link
                  to="/"
                  className="text-gray-300 hover:text-[#F85B20] transition-colors"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/about"
                  className="text-gray-300 hover:text-[#F85B20] transition-colors"
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  to="/calculator"
                  className="text-gray-300 hover:text-[#F85B20] transition-colors"
                >
                  Calculator
                </Link>
              </li>
              <li>
                <Link
                  to="/apply"
                  className="text-gray-300 hover:text-[#F85B20] transition-colors"
                >
                  Apply Now
                </Link>
              </li>
            </ul>
          </div>

          {/* Branch Info */}
          <div className="col-span-2 grid grid-cols-1 md:grid-cols-2 gap-8">
            {branches.map((branch) => (
              <div key={branch.name} className="text-center md:text-left">
                <h3 className="font-bold text-lg mb-4 text-white">
                  {branch.name}
                </h3>
                <div className="space-y-2 text-gray-300">
                  {branch.phones.map((phone) => (
                    <p key={phone}>{phone}</p>
                  ))}
                  <p>{branch.email}</p>
                  {branch.address.map((line, index) => (
                    <p key={index}>{line}</p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Copyright */}
        <div className="text-center pt-8 border-t border-gray-700">
          <p className="text-gray-400">
            © {currentYear} Cashnet Finance. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
