// src/pages/Apply.tsx
import React from "react";
import LoanForm from "../components/LoanForm";

const Apply: React.FC = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">
        Apply for a Loan
      </h1>
      <LoanForm />
    </div>
  );
};

export default Apply;
