import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import Header from "./components/Header";
import Requirements from "./components/Requirements";
import Branches from "./components/Branches";
import Features from "./components/Features";
import HowItWorks from "./components/HowItWorks";
import Testimonials from "./components/Testimonials";
import QualifySection from "./components/QualifySection";
import Footer from "./components/Footer";
import About from "./pages/About";
import Calculator from "./pages/Calculator";
import Apply from "./pages/Apply";
import zeroInterest from "./assets/zerointerest.webp";

// Scroll restoration
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const Home: React.FC = () => {
  return (
    <main>
      <div className="w-full bg-gray-900">
        <div className="max-w-7xl mx-auto px-4 py-8">
          <div className="mb-8">
            {/* Hidden on desktop, shown on mobile */}
            <div className="md:hidden text-center">
              <h1 className="text-4xl font-bold text-white mb-2">Welcome to</h1>
              <h1 className="text-4xl font-bold text-orange-500">
                Cashnet Finance
              </h1>
            </div>

            {/* Hidden on mobile, shown on desktop */}
            <h1 className="hidden md:block text-4xl font-bold text-center">
              <span className="text-white">Welcome to</span>{" "}
              <span className="text-orange-500">Cashnet Finance</span>
            </h1>
          </div>

          <p className="text-center text-white font-bold text-lg mb-12">
            Fast, reliable loans for when you need them most
          </p>

          <div className="max-w-4xl mx-auto">
            <img
              src={zeroInterest}
              alt="Zero Interest Promotion"
              className="w-full h-auto rounded-lg shadow-md mb-6"
            />
            <Link
              to="/apply"
              className="block w-full bg-orange-500 text-white text-center py-4 rounded-lg text-lg font-semibold hover:bg-orange-600 transition-colors"
            >
              Apply Now
            </Link>
          </div>
        </div>
      </div>

      <QualifySection />
      <Requirements />
      <Branches />
      <Features />
      <HowItWorks />
      <Testimonials />
    </main>
  );
};

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="min-h-screen bg-slate-50">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/calculator" element={<Calculator />} />
          <Route path="/apply" element={<Apply />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
