import React from "react";
import fastApprovalIcon from "../assets/fastapprovals.webp"; // You'll rename this
import fastPaymentIcon from "../assets/fastpayments.webp"; // You'll rename this
import installmentsIcon from "../assets/installments.webp"; // You'll rename this

const Features: React.FC = () => {
  return (
    <div className="w-full bg-white py-16">
      <div className="max-w-7xl mx-auto px-4">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-4">
          How We Help You
        </h2>
        <div className="h-1 w-36 bg-[#F85B20] mx-auto mb-16"></div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-12 justify-items-center max-w-4xl mx-auto">
          {/* Fast Approvals */}
          <div className="flex flex-col items-center">
            <div className="w-32 h-32 rounded-full border-2 border-[#F85B20] flex items-center justify-center mb-6 group hover:bg-[#F85B20] transition-colors">
              <img
                src={fastApprovalIcon}
                alt="Fast Approvals"
                className="w-10 h-10 group-hover:brightness-0 group-hover:invert transition-colors"
              />
            </div>
            <h3 className="text-xl font-semibold text-gray-800 text-center">
              Fast Approvals
            </h3>
          </div>

          {/* Fast payments */}
          <div className="flex flex-col items-center">
            <div className="w-32 h-32 rounded-full border-2 border-[#F85B20] flex items-center justify-center mb-6 group hover:bg-[#F85B20] transition-colors">
              <img
                src={fastPaymentIcon}
                alt="Fast Payments"
                className="w-10 h-10 group-hover:brightness-0 group-hover:invert transition-colors"
              />
            </div>
            <h3 className="text-xl font-semibold text-gray-800 text-center">
              Fast payments
            </h3>
          </div>

          {/* Instalments */}
          <div className="flex flex-col items-center">
            <div className="w-32 h-32 rounded-full border-2 border-[#F85B20] flex items-center justify-center mb-6 group hover:bg-[#F85B20] transition-colors">
              <img
                src={installmentsIcon}
                alt="Installments"
                className="w-10 h-10 group-hover:brightness-0 group-hover:invert transition-colors"
              />
            </div>
            <h3 className="text-xl font-semibold text-gray-800 text-center">
              You can pay in
              <br />
              instalments
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
