// Testimonials.tsx
import React, { useState, useEffect } from "react";
import testimonialImage1 from "../assets/testimonial1.webp"; // You'll rename this
import testimonialImage2 from "../assets/testimonial3.webp"; // You'll rename this
import testimonialImage3 from "../assets/testimonial2.webp"; // You'll rename this

interface Testimonial {
  id: number;
  name: string;
  role: string;
  quote: string;
  image: string;
}

const testimonials: Testimonial[] = [
  {
    id: 1,
    name: "John Kaula",
    role: "Client",
    quote:
      "I have been a Cashnet Finance client since 2012. I tried other short term financial providers but I have found Cashnet Finance exceptional.",
    image: testimonialImage1,
  },
  {
    id: 2,
    name: "Maria Hausiku",
    role: "Client",
    quote:
      "The quick approval process and professional service made getting a loan stress-free. I highly recommend their services to anyone needing financial support.",
    image: testimonialImage2,
  },
  {
    id: 3,
    name: "Thomas Shilongo",
    role: "Client",
    quote:
      "What sets Cashnet Finance apart is their understanding of customer needs. Their flexible repayment terms helped me manage my finances better.",
    image: testimonialImage3,
  },
];

const Testimonials: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % testimonials.length);
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  const goToSlide = (index: number) => {
    setCurrentSlide(index);
  };

  return (
    <div className="w-full bg-gray-50 py-20">
      <div className="max-w-7xl mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-800 mb-4">
            What Our Clients Say
          </h2>
          <div className="h-1 w-24 bg-[#F85B20] mx-auto mb-6"></div>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Discover the voices of satisfied clients who have benefited from
            Cashnet Finance solutions. Read their stories and experiences below.
          </p>
        </div>

        <div className="relative max-w-4xl mx-auto">
          {/* Testimonial Slides */}
          <div className="overflow-hidden">
            <div
              className="transition-transform duration-500 ease-in-out flex"
              style={{ transform: `translateX(-${currentSlide * 100}%)` }}
            >
              {testimonials.map((testimonial) => (
                <div
                  key={testimonial.id}
                  className="w-full flex-shrink-0 flex flex-col items-center px-6"
                >
                  <div className="w-24 h-24 rounded-full bg-gray-200 mb-6 flex items-center justify-center overflow-hidden">
                    <img
                      src={testimonial.image}
                      alt={testimonial.name}
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <blockquote className="text-center mb-6 text-lg text-gray-700 max-w-2xl">
                    "{testimonial.quote}"
                  </blockquote>
                  <div className="text-center">
                    <h3 className="text-xl font-semibold text-[#F85B20]">
                      {testimonial.name}
                    </h3>
                    <p className="text-gray-600">{testimonial.role}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Dots Navigation */}
          <div className="flex justify-center gap-3 mt-8">
            {testimonials.map((_, index) => (
              <button
                key={index}
                onClick={() => goToSlide(index)}
                className={`w-3 h-3 rounded-full transition-colors duration-300 ${
                  currentSlide === index ? "bg-[#F85B20]" : "bg-gray-300"
                }`}
                aria-label={`Go to testimonial ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
