import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Menu, X } from "lucide-react";
import cashnetLogo from "../assets/cashnetlogo.webp";

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleApplyNow = () => {
    navigate("/apply");
    setIsMenuOpen(false);
  };

  return (
    <header className="bg-white shadow-sm">
      <nav className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 justify-between items-center">
          {/* Logo */}
          <div className="flex items-center">
            <Link to="/">
              <img
                src={cashnetLogo}
                alt="CashNetFinance"
                className="h-12 w-auto"
              />
            </Link>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex md:items-center md:space-x-8">
            <Link
              to="/"
              className="font-bold text-gray-700 hover:text-[#F14C07]"
            >
              Home
            </Link>
            <Link
              to="/calculator"
              className="font-bold text-gray-700 hover:text-[#F14C07]"
            >
              Calculator
            </Link>
            <Link
              to="/about"
              className="font-bold text-gray-700 hover:text-[#F14C07]"
            >
              About
            </Link>
            <button
              onClick={handleApplyNow}
              className="bg-[#F14C07] text-white px-4 py-2 rounded-md hover:bg-[#E04E1A] font-bold"
            >
              Apply Now
            </button>
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-gray-700 hover:text-[#F14C07]"
              aria-label="Toggle menu"
            >
              {isMenuOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>

        {/* Mobile Navigation */}
        {isMenuOpen && (
          <div className="md:hidden">
            <div className="space-y-1 pb-3 pt-2">
              <Link
                to="/"
                className="block px-3 py-2 font-bold text-gray-700 hover:bg-orange-50 hover:text-[#F14C07]"
              >
                Home
              </Link>
              <Link
                to="/calculator"
                className="block px-3 py-2 font-bold text-gray-700 hover:bg-orange-50 hover:text-[#F14C07]"
              >
                Calculator
              </Link>
              <Link
                to="/about"
                className="block px-3 py-2 font-bold text-gray-700 hover:bg-orange-50 hover:text-[#F14C07]"
              >
                About
              </Link>
              <div className="px-3 py-2">
                <button
                  onClick={handleApplyNow}
                  className="w-full bg-[#F14C07] text-white px-4 py-2 rounded-md hover:bg-[#E04E1A] font-bold"
                >
                  Apply Now
                </button>
              </div>
            </div>
          </div>
        )}
      </nav>
    </header>
  );
};

export default Header;
